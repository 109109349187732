import React, {useEffect, useState} from 'react';

import {If, Then, Else} from 'react-if';

import CryptoJS from 'crypto-js';
import CMAC from 'cryptojs-extension/build/cmac'

import Navbar from 'react-bootstrap/Navbar';

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';

import useLocalStorage from './useLocalStorage';
import './App.css';

// Silence warning
void(CMAC)

const { location } = window;
const { search } = location;
const params = new URLSearchParams(search);

function mact(mac) {
  let t = ''
  for(var i = 0; i < mac.length; i++) {
    const byte = (i / 2) >>> 0;
    if (byte % 2 === 1) {
      t += mac[i];
    }
  }
  return t;
}

function App() {
  const [status, setStatus] = useState('warning');

  const [uidParam, setUidParam] = useLocalStorage('uidParam', 'uid');
  const [counterParam, setCounterParam] = useLocalStorage('counterParam', 'ctr');
  const [cmacParam, setCmacParam] = useLocalStorage('cmacParam', 'c');
  const [dynamicFileDataOffset, setDynamicFileDataOffset] = useLocalStorage('dynamicFileDataOffset', 20);
  const [key, setKey] = useLocalStorage('key', '00000000000000000000000000000000');

  const [dynamicFileData, setDynamicFileData] = useState('');
  const [SesSDMFileReadMAC, setSesSDMFileReadMAC] = useState('');
  const [SDMMACfull, setSDMMACfull] = useState('');
  const [MACt, setMACt] = useState('');

  useEffect(() => {
    async function submitForm() {
      if (params.has('picc_data')) {
        params.set('form-name', 'request');
        try {
          const response = await fetch('/', {
            method: 'POST',
            headers: {
              "Content-Type": "application/x-www-form-urlencoded"
            },
            body: params.toString(),
          });
          console.log('form response', await response.text());
        } catch(e) {
          console.log(e);
        }
      }
    }
    submitForm();
  }, []);


  useEffect(() => {
    const url = location.toString();
    const counter = params.get(counterParam)
    const uid = params.get(uidParam)
    const end = url.indexOf('&' + cmacParam + '=') + cmacParam.length + 2;
    const dynamicFileData = url.slice(dynamicFileDataOffset, end)
    setDynamicFileData(dynamicFileData);

    if (counter && uid && dynamicFileData) {
      const reverseCounter = counter.match(/[a-fA-F0-9]{2}/g).reverse().join('')
      const sv2 = CryptoJS.enc.Hex.parse('3cc300010080' + uid + reverseCounter)
      const SDMFileRead = CryptoJS.enc.Hex.parse(key)
      const SesSDMFileReadMAC = CryptoJS.CMAC(SDMFileRead, sv2);
      const SDMMACfull = CryptoJS.CMAC(SesSDMFileReadMAC, dynamicFileData);
      setSesSDMFileReadMAC(SesSDMFileReadMAC.toString(CryptoJS.enc.Hex))
      setSDMMACfull(SDMMACfull.toString(CryptoJS.enc.Hex))
    }
  }, [uidParam, counterParam, cmacParam, dynamicFileDataOffset, key])

  useEffect(() => {
    if (cmacParam && SDMMACfull) {
      const c = params.get(cmacParam)
      const t = mact(SDMMACfull)
      setMACt(t);
      if (t.toLowerCase() === c.toLowerCase()) {
        setStatus('success')
      } else {
        setStatus('warning')
      }
    }
  }, [cmacParam, SDMMACfull])

  //https://my424dna.com/?picc_data=FDE4AFA99B5C820A2C1BB0F1C792D0EB&enc=94592FDE69FA06E8E3B6CA686A22842B&cmac=C48B89C17A233B2C

  return (
    <div className="App">
      <Navbar>
        <Navbar.Brand>My 424 DNA</Navbar.Brand>
      </Navbar>

      <Container className="text-center pt-5">
        <If condition={params.has('picc_data')}>
          <Then>
            <Row className="text-center justify-content-center mt-1">
              <Col xs={6}>
                <Alert variant='success'>
                  PICC: {params.get('picc_data')}
                </Alert>
                <Alert variant='success'>
                  ENC: {params.get('enc')}
                </Alert>
                <Alert variant='success'>
                  CMAC: {params.get('cmac')}
                </Alert>
              </Col>
            </Row>
          </Then>
          <Else>
            <Row>
              <Col xs={6}>
                <Form>
                  <Row>
                    <Form.Label>UID parameter name</Form.Label>
                    <Col>
                      <Form.Control type="text" defaultValue={uidParam} onChange={(e) => setUidParam(e.target.value)} />
                    </Col>
                  </Row>
                  <Row>
                    <Form.Label>Counter parameter name</Form.Label>
                    <Col>
                      <Form.Control type="text" defaultValue={counterParam} onChange={(e) => setCounterParam(e.target.value)} />
                    </Col>
                  </Row>
                  <Row>
                    <Form.Label>CMAC parameter name</Form.Label>
                    <Col>
                      <Form.Control type="text" defaultValue={cmacParam} onChange={(e) => setCmacParam(e.target.value)} />
                    </Col>
                  </Row>
                  <Row>
                    <Form.Label>Dynamic File Data offset</Form.Label>
                    <Col>
                      <Form.Control type="text" defaultValue={dynamicFileDataOffset} onChange={(e) => setDynamicFileDataOffset(e.target.value)} />
                    </Col>
                  </Row>
                  <Row>
                    <Form.Label>Key</Form.Label>
                    <Col>
                      <Form.Control type="text" defaultValue={key} onChange={(e) => setKey(e.target.value)} />
                    </Col>
                  </Row>
                </Form>
              </Col>
              <Col xs={6}>
                <p>dynamicFileData: <code>{dynamicFileData}</code></p>
                <p>SesSDMFileReadMAC: <code>{SesSDMFileReadMAC}</code></p>
                <p>SDMMACfull: <code>{SDMMACfull}</code></p>
                <Alert variant={status}>
                  CMAC: {MACt}
                </Alert>
              </Col>
            </Row>
          </Else>
        </If>
      </Container>
      <footer className="footer font-small mx-auto pt-5">
        <Container fluid className="text-center">
          <Row noGutters>
            <Col>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
}

export default App;
